.pagination-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}

.pagination-btn {
    width: 25px;
    height: 25px;
    border: 1px solid #75747F;
    border-radius: 7px;
    background-color: transparent;
    padding: 0%;
    margin: 0px 2.5px;
}

.pagination-btn span {
    font-size: 20px;
    color: #75747F;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-btn:active span{
    color: #1C1C30;
}
.pagination-btn:active {
    border-color:#1C1C30;
}

.pagination-btn:hover span{
    color: #494853;
}
.pagination-btn:hover {
    border-color:#494853;
}
