.dropup-menu {
    position: absolute;
    display: block;
    border-color: #e1e1e1;
    background-color: #FFFFFF;
    border-radius: 7px;
    bottom: 38px;
    left: 38px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 6px 12px;
    text-align: center;
    font-size: 12px;

}

.n-articles-displayed {
    border: 1px solid;
    border-radius: 7px; 
    color: #75747F;
    width: 52px; 
    margin:8px;
}
.n-articles-displayed:active span{
    color: #1C1C30;
}
.n-articles-displayed:active {
    border-color:#1C1C30;
}

.n-articles-displayed:hover span{
    color: #494853;
}
.n-articles-displayed:hover {
    border-color:#494853;
}
