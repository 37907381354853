.last-updated-container {
    color: #75747F; 
    font-size: 12px;
    position:relative;
}

.last-updated-ago {
    color:#1C1C30;
    margin: 0px 3px
}

.last-updated-date{
    color: #75747F;
    font-size: 10px;
    background-color: #FBFBFC;
    position: absolute;
    top: -25px;
    left: 90px;
    height: 24px;
    width: 70%;
    text-align: center;
    padding: 2px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 6px 12px;
    z-index: 100;
}